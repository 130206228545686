import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Slider from 'rc-slider'
import { useSprings, animated } from 'react-spring'
import 'rc-slider/assets/index.css'

import { Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'
import useWindowSize from 'lib/ui/useWindowSize'
import { marks, sliderConfig, values } from './constants'

const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);

  ${screenMin.m`
    grid-template-columns: repeat(12, 1fr);
  `}

  div:first-child {
    align-self: flex-start;
  }

  div:last-child {
    align-self: center;
  }

  ${screenMax.m`
    text-align: left;
  `}
`

const StyledPhotoFrame = styled(Image)`
  aspect-ratio: 1 / 1;
  border: solid ${COLOR.WHITE} 10px;
  background-color: ${COLOR.WHITE};
  position: relative;
  box-shadow: ${BORDER.SHADOW.M};
  transform: rotate(10deg);

  ${screenMin.m`
    max-width: 75%;
    top: -27px;
    margin-top: ${GTR.L};
  `}

  ${screenMin.l`
    transform-origin: top left;
  `}
`

const ContentBlockWrapper = styled(Wrapper)`
  border-radius: ${BORDER.RADIUS.XL};
  box-shadow: ${BORDER.SHADOW.M};
  position: relative;
`

const StyledP = styled(P)`
  font-size: 20px;
  text-align: left;

  ${screenMin.m`
    font-size: ${FONT.SIZE.L};
  `}
`

const ContentBlock = styled(animated.div)`
  text-align: center;
  position: absolute;
  inset: 0;
`

const StyledSliderWrapper = styled(Wrapper)`
  .rc-slider-handle {
    position: relative;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid ${COLOR.WHITE};
      position: absolute;
      top: 24px;
      left: -10px;
    }
  }

  ${screenMax.m`
    padding-left: ${GTR.L};
    padding-right: ${GTR.L};
  `}
`

const GiftSlider = () => {
  const [sliderValue, setSliderValue] = useState(0)
  const blockRefs = useRef<(HTMLDivElement | null)[]>([])
  const sliderRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowSize()

  // Adjust height of content blocks to match current block
  useEffect(() => {
    if (wrapperRef.current && blockRefs.current.length > 0) {
      const heights = blockRefs.current.map((block) => block?.offsetHeight || 0)
      const highestElementHeight = Math.max(...heights)
      wrapperRef.current.style.height = `${highestElementHeight}px`
    }
  }, [sliderValue, windowWidth])

  // Hide mark text elements from screen readers - The values (and associated
  // descriptions) are read out when the user changes the value of the slider,
  // so having the mark text nodes announced is redundant and confusing.
  useEffect(() => {
    if (!sliderRef.current) {
      return
    }
    sliderRef.current
      .querySelector('.rc-slider-mark')
      ?.setAttribute('aria-hidden', 'true')
  }, [sliderRef.current])

  // Animation for content blocks
  const springs = useSprings(
    values.length,
    values.map((val) => ({
      opacity: val === sliderValue ? 1 : 0,
      from: { opacity: 0 },
    }))
  )

  // Update current value when slider changes
  const handleSliderChange = (value: number | number[]) => {
    if (typeof value === 'number') {
      setSliderValue(value)
    }
  }

  const handleAfterChange = (value: number | number[]) => {
    if (typeof value === 'number') {
      const closestValue = values.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      )
      setSliderValue(closestValue)
    }
  }

  const ariaValueTextFormatterForHandle = (value: number) => {
    const config = sliderConfig.find((cfg) => cfg.value === value)
    return config ? config.text : `${value}`
  }

  return (
    <Wrapper margin={['XXL', 0, 0]}>
      <StyledSliderWrapper maxWidthInColumns={8} margin={[0, 'auto']}>
        <div ref={sliderRef}>
          <Slider
            min={0}
            max={100}
            step={25}
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
            onChangeComplete={handleAfterChange}
            ariaValueTextFormatterForHandle={ariaValueTextFormatterForHandle}
            styles={{
              track: {
                transitionDuration: '0.3s',
                background: 'none',
                height: '14px',
              },
              handle: {
                transitionDuration: '0.3s',
                width: '26px',
                height: '26px',
                border: `8px solid ${COLOR.BLACK}`,
                marginTop: '-7px',
                boxShadow: `none !important`,
              },
              rail: {
                transitionDuration: '0.3s',
                background: COLOR.WHITE,
                height: '12px',
                border: `1px solid ${COLOR.GREY.LIGHT}`,
              },
            }}
            dotStyle={{
              border: `3px solid ${COLOR.GREY.MEDIUM}`,
              height: '12px',
              width: '12px',
              bottom: '-8px',
            }}
          />
        </div>
      </StyledSliderWrapper>

      <ContentBlockWrapper
        aria-hidden
        background={COLOR.WHITE}
        maxWidthInColumns={9}
        padding={[0, 'XL', 'M']}
        margin={['L', 'auto']}
        noTrim
      >
        <div ref={wrapperRef}>
          {springs.map((props, index) => (
            <ContentBlock key={values[index]} style={props}>
              <div
                ref={(el) => {
                  blockRefs.current[index] = el
                }}
              >
                <StyledGrid padding={['M', 'L', 'M']} paddingFromL="XL">
                  <Grid.Item span={4} spanFromM={3}>
                    <StyledPhotoFrame
                      path={sliderConfig[index].image}
                      width={118}
                      widthFromM={300}
                      alt=""
                      stretch
                    />
                  </Grid.Item>
                  <Grid.Item span={4} spanFromM={9}>
                    <StyledP color={COLOR.BLACK}>
                      {sliderConfig[index].text}
                    </StyledP>
                  </Grid.Item>
                </StyledGrid>
              </div>
            </ContentBlock>
          ))}
        </div>
      </ContentBlockWrapper>
    </Wrapper>
  )
}

const GiftSliderSection = (): ReactElement => (
  <Wrapper background={COLOR.ACCENT.PRIMARY_20}>
    <Wrapper container maxWidthInColumns={10}>
      <H tag="h2" size="L" margin={[0, 0, 'XS']} decorative centeredFromM>
        How far could your gift go?
      </H>
      <P size="M" centeredFromM>
        <PreventOrphan>
          Move the slider to see the impact you could have by leaving part of
          your estate in your will. There is no obligation to leave a gift to
          Macmillan in your will, but this is what a gift could fund.
        </PreventOrphan>
      </P>
      <GiftSlider />
      <P padding={['L', 0, 0]} centeredFromM>
        This calculation uses an average estate value of £505,000
      </P>
    </Wrapper>
  </Wrapper>
)

export default GiftSliderSection
